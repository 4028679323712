import React, { useEffect, useState } from "react";
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import urljoin from 'url-join';
import { DiscussionEmbed } from 'disqus-react';
import Layout3 from '../components/layout3';
import SEO from '../components/seo';
import PostCard from '../components/post-card/post-card';
import PostDetails from '../components/post-details/post-details';
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from 'react-share';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoReddit,
} from 'react-icons/io';
import {
  BlogPostDetailsWrapper,
  RelatedPostWrapper,
  RelatedPostItems,
  RelatedPostTitle,
  RelatedPostItem,
  BlogPostFooter,
  PostShare,
  PostTags,
  BlogPostComment,
  HonbunWrapper,
} from './templates.style';

import Sticky from 'react-stickynode';
import Styles from "./blog-post.module.css"
import Cesium1 from "../components/cesium/cesium1"
import Cesium2dis from "../components/cesium/cesium2dis"
import Refresh from "../components/refresh"

import Hanrei from "../containers/hanrei-kokudo"
import CesiumDiv from "../pages/cesiumDiv";
import { styles } from 'styled-system';
// import CesiumContainer from "../pages/cesiumContainer5ref"
import CesiumContainer from "../pages/cesiumContainer7ref"

import { Viewer, KmlDataSource, ImageryLayer, CameraFlyTo } from "resium"
import { Ion, Cartesian3, UrlTemplateImageryProvider, Credit } from 'cesium'

const totalCases = '/kml/sample.kml'
const totalDeaths = '/kml/sample.kmz'
const totalCasesPM = '/kml/北海道函館市_H26小売業販売額×公共交通利用圏.kmz'
const totalDeathsPM = '/kml/sample.kml'


const imageryProvider = new UrlTemplateImageryProvider({
  url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
  credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
})

const BlogPostTemplate = (props: any) => {
  const post = props.data.markdownRemark;
  const { edges } = props.data.allMarkdownRemark;
  const title = post.frontmatter.title;
  const slug = post.fields.slug;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const shareUrl = urljoin(siteUrl, slug);
  const kmlsrc='https://empire-dev3.kashika.net/Cesium/Apps/kashika/?sourceid='+post.frontmatter.no+'/福岡県糸島市_' + post.frontmatter.kml_file
  const kmldown='https://empire-dev3.kashika.net/kml/'+post.frontmatter.no+'/福岡県糸島市_' + post.frontmatter.kml_file
  const kmlsrc1='https://empire-dev3.kashika.net/Cesium/Apps/kashika/?sourceid='+post.frontmatter.no+'/国土_' + post.frontmatter.kml_file

  const kmlsrc2='/kml/'+post.frontmatter.no+'/国土_' + post.frontmatter.kml_file

  const kmlsrc3='/kml/'+post.frontmatter.no+'/国土_' + post.frontmatter.kml_file.substring(0, post.frontmatter.kml_file.length-1) + 'l'

  const kmldown1='https://empire-dev3.kashika.net/kml/'+post.frontmatter.no+'/国土_' + post.frontmatter.kml_file

  const kmldown1a='/kml/'+post.frontmatter.no+'/国土_' + post.frontmatter.kml_file.substring(0, post.frontmatter.kml_file.length-1) + 'l'


  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: slug, title },
  };



  const [showTotalCases, setShowTotalCases] = useState(true)
  const [showTotalDeaths, setShowTotalDeaths] = useState(false)
  const [showTotalCasesPM, setShowTotalCasesPM] = useState(false)
  const [showTotalDeathsPM, setShowTotalDeathsPM] = useState(false)

  const [customLatitude, setCustomLatitude] = useState(35)
  const [customLongitude, setCustomLongitude] = useState(135)
  const [showModal, setShowModal] = useState(false)

  const toolBoxStyle = {
    width: '20%',
    color: 'white',
    top: '0px',
    left: '75%',
    zIndex: 2,
    position: 'relative'
  }







  return (
    <Layout3>
      
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />


        <Sticky top={0} innerZ={9999} activeClass="nav-sticky">
          <div>
          <div className={Styles.bar}>
            <div style={{display:"flex"}}>
              <div className={Styles.headerleft}>
                {/* <Cityset1 /> */}
                <a href={"/tags/sdgs-"+post.frontmatter.sdgs_no}><img style={{width:"100%",margin:"2px"}} src={"/sdgsicon/sdg_"+post.frontmatter.sdgs_no+".png"}/></a>
              </div>
              <div className={Styles.headerno}>
                {post.frontmatter.no}
              </div>
              <div className={Styles.headertitle}>
                {post.frontmatter.title}
              </div>
              <div className={Styles.headertitle2}>
                {post.frontmatter.height_name+"×"+post.frontmatter.color_name}
              </div>
              <div className={Styles.headerright}>
                <a className={Styles.button} href="/top">HOME</a>
              </div>
            </div>
          </div>
          </div>
        </Sticky>

        <div>
  <Link to={"/"+post.frontmatter.no+"/0"} className={Styles.dis2}><h2>国土像を俯瞰する（全国の傾向を見る）</h2></Link>
</div>


  <div className={Styles.cesium2dis}>

  <div style={{display:"flex"}}>
    <div style={{width:"50%",margin:"0% 0% 0% 0%"}}>
      <div className={Styles.kakomitape4}><span className={Styles.titletape4}>
        解説
        </span>
        解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。
      </div>
      <div>
      <div>
        <Hanrei
            no={post.frontmatter.no}
            title={post.frontmatter.title}
            date={post.frontmatter.date}
            sdgs_no={post.frontmatter.sdgs_no}
            sdgs_main={post.frontmatter.sdgs_main}
            sdgs_subcat={post.frontmatter.sdgs_subcat}
            kou_tisou_pt={post.frontmatter.kou_tisou_pt}
            kou_tisou_chp={post.frontmatter.kou_tisou_chp}
            kou_tisou_sec={post.frontmatter.kou_tisou_sec}
            kou_tisou_sbsec={post.frontmatter.kou_tisou_sbsec}
            kou_tisou_title={post.frontmatter.kou_tisou_title}
            kou_titan_pt={post.frontmatter.kou_titan_pt}
            kou_titan_chp={post.frontmatter.kou_titan_chp}
            kou_titan_sec={post.frontmatter.kou_titan_sec}
            kou_titan_sbsec={post.frontmatter.kou_titan_sbsec}
            kou_titan_title={post.frontmatter.kou_titan_title}
            description={post.frontmatter.description}
            height_name={post.frontmatter.height_name}
            height_origin={post.frontmatter.height_origin}
            height_origin_note={post.frontmatter.height_origin_note}
            height_origin_url_name={post.frontmatter.height_origin_url_name}
            height_origin_url_name2={post.frontmatter.height_origin_url_name2}
            height_origin_url={post.frontmatter.height_origin_url}
            height_origin_url2={post.frontmatter.height_origin_url2}
            color_name={post.frontmatter.color_name}
            color_origin_note={post.frontmatter.color_origin_note}
            color_origin={post.frontmatter.color_origin}
            color_origin_url_name={post.frontmatter.color_origin_url_name}
            color_origin_url2_name={post.frontmatter.color_origin_url2_name}
            color_origin_url={post.frontmatter.color_origin_url}
            color_origin_url2={post.frontmatter.color_origin_url2}
            sokuchi={post.frontmatter.sokuchi}
            mesh_size={post.frontmatter.mesh_size}
            year_display={post.frontmatter.year_display}
            honbun={post.frontmatter.honbun}
            chyusyaku={post.frontmatter.chyusyaku}
            hosoku={post.frontmatter.hosoku}
            kml_file={post.frontmatter.kml_file}
            hanrei1_color={post.frontmatter.hanrei1_color}
            hanrei2_color={post.frontmatter.hanrei2_color}
            hanrei3_color={post.frontmatter.hanrei3_color}
            hanrei4_color={post.frontmatter.hanrei4_color}
            hanrei1_name={post.frontmatter.hanrei1_name}
            hanrei2_name={post.frontmatter.hanrei2_name}
            hanrei3_name={post.frontmatter.hanrei3_name}
            hanrei4_name={post.frontmatter.hanrei4_name}
            height_ratio={post.frontmatter.height_ratio}
            kokudo_hanrei1_name={post.frontmatter.kokudo_hanrei1_name}
            kokudo_hanrei2_name={post.frontmatter.kokudo_hanrei2_name}
            kokudo_hanrei3_name={post.frontmatter.kokudo_hanrei3_name}
            kokudo_hanrei4_name={post.frontmatter.kokudo_hanrei4_name}
            kokudo_height_ratio={post.frontmatter.kokudo_height_ratio}
          />
        </div>
      </div>
    </div>
    <div style={{width:"50%", margin:"0 2% 0 0"}}>
      <div style={{display: "flex"}}>
      <div className={Styles.panel}>
        <div>
          <h2>
            全国表示
          </h2>
        </div>
        <div className={Styles.cesiumcontainer}>
            <CesiumContainer
              dir={kmldown1a}
              downdir={kmldown1}
              // dir="/kml/sample.kml"
              width="100%"
              height="700px"
              no={post.frontmatter.no}
            />
            </div>

      </div>
      </div>


      </div>
    </div>
    </div>




    <div>
        <Link to={"/"+post.frontmatter.no+"/tenkei"} className={Styles.dis2}>
          <h2>典型的な地域とその解説を見る</h2>
        </Link>
      </div>

      <div>
        <Link to={"/"+post.frontmatter.no+"/erabu"} className={Styles.dis2}>
          <h2>自分で地域を選んで探究する</h2>
        </Link>
      </div>

      <div>
        <Link to={"/"+post.frontmatter.no+"/hikaku"} className={Styles.dis2}>
          <h2>2地域を比較して探究する</h2>
        </Link>
      </div>

      <div>
        <Link to={"/"+post.frontmatter.no+"/kanren"} className={Styles.dis2}>
          <h2>関連するデータを調べる</h2>
        </Link>
      </div>

      <div>
        <Link to={"/"+post.frontmatter.no+"/syousai"} className={Styles.dis2}>
          <h2>データの詳細情報を確認する</h2>
        </Link>
      </div>

    </Layout3>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlugkokudo($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fileAbsolutePath
      fields {
        slug
      }
      frontmatter {
        no
        title
        date(formatString: "DD MMM, YYYY")
        description
        tags
        sdgs_no
        sdgs_main
        sdgs_subcat
        kou_tisou_pt
        kou_tisou_chp
        kou_tisou_sec
        kou_tisou_sbsec
        kou_tisou_title
        kou_titan_pt
        kou_titan_chp
        kou_titan_sec
        kou_titan_sbsec
        kou_titan_title
        height_name
        height_origin
        height_origin_note
        height_origin_url_name
        height_origin_url
        height_origin_url2_name
        height_origin_url2
        height_origin_url3_name
        height_origin_url3
        height_origin_url4_name
        height_origin_url4
        color_name
        color_origin
        color_origin_note
        color_origin_url_name
        color_origin_url
        color_origin_url2_name
        color_origin_url2
        color_origin_url3_name
        color_origin_url3
        color_origin_url4_name
        color_origin_url4
        sokuchi
        mesh_size
        year_display
        honbun
        chyusyaku
        hosoku
        tags
        kml_file
        y202012
        y202101
        y202102
        description
        hanrei1_color
        hanrei2_color
        hanrei3_color
        hanrei4_color
        hanrei1_name
        hanrei2_name
        hanrei3_name
        hanrei4_name
        height_ratio
        kokudo_hanrei1_name
        kokudo_hanrei2_name
        kokudo_hanrei3_name
        kokudo_hanrei4_name
        kokudo_height_ratio
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tag } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            no
            title
            tags

            sdgs_no
            sdgs_main
            sdgs_subcat
            kou_tisou_pt
            kou_tisou_chp
            kou_tisou_sec
            kou_tisou_sbsec
            kou_tisou_title
            kou_titan_pt
            kou_titan_chp
            kou_titan_sec
            kou_titan_sbsec
            kou_titan_title
            height_name
            height_origin
            height_origin_note
            height_origin_url_name
            height_origin_url
            height_origin_url2_name
            height_origin_url2
            height_origin_url3_name
            height_origin_url3
            height_origin_url4_name
            height_origin_url4
            color_name
            color_origin
            color_origin_note
            color_origin_url_name
            color_origin_url
            color_origin_url2_name
            color_origin_url2
            color_origin_url3_name
            color_origin_url3
            color_origin_url4_name
            color_origin_url4
            sokuchi
            mesh_size
            year_display
            honbun
            chyusyaku
            hosoku
            tags
            kml_file
            y202012
            y202101
            y202102
            description
            hanrei1_name
            hanrei1_color
            hanrei2_name
            hanrei2_color
            hanrei3_name
            hanrei3_color
            hanrei4_name
            hanrei4_color
            height_ratio
          }
        }
      }
    }
  }
`;

